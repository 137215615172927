/* Hide scrollbar for Chrome, Safari and Opera */
.css-cbxwou::-webkit-scrollbar {
  display: none !important;
  width: 0px;
}

/* Hide scrollbar for IE and Edge */
.css-cbxwou {
  -ms-overflow-style: none !important;
}
