.rbc-label {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.rbc-today {
  background-color: white;
}
.rbc-time-header {
  height: 20px;
}
.rbc-header {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
.rbc-current-time-indicator {
  background-color: red;
}
.rbc-timeslot-group {
  height: 80px;
}
.rbc-event-label {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.rbc-event-content {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.rbc-event {
  background-color: #D093C94D;
  border: #D093C9;
  color: black;
  border-left-color: #812187;
  border-left-width: 4px !important;
  border-left-style: solid !important;
}
.rbc-event.rbc-selected {
  background-color: #D093C94D;
}
.rbc-time-column {
  width: 80px;
}
.rbc-time-slot {
  margin-left: 20px;
}
.rbc-time-content {
  border-top: none;
}
.css-1hppjzv .MuiIconButton-root {
  padding: 0px !important;
}
.css-1hppjzv .MuiInputBase-root {
  font-size: 0.9rem !important;
}
.css-1hppjzv .MuiOutlinedInput-adornedEnd {
  padding-right: 9px !important;
}
