body {
    margin: 0px;
    -webkit-user-select: none;
}

.MuiDrawer-root {
    z-index: 10;
}

.MuiDrawer-paper {
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2) !important;
    background: white !important;
    margin-top: 0 !important;
    padding: 8px !important;
}

.MuiAppBar-root {
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0,0,0,0.12) !important;
}

.MuiDrawer-paper .MuiMenuItem-root {
    font-size: 0.875rem !important;
    padding: 8px !important;
    min-height: initial !important;
    border-radius: 4px;
}

.MuiDrawer-paper .MuiMenuItem-root .MuiListItemIcon-root {
    min-width: 56px !important;
}

.MuiDrawer-paper .MuiDivider-root {
    margin: 16px 0 16px 64px !important;
}

.RaLayout-content-4 h4, .jss4 h4 {
    color: rgba(0,0,0,0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin: 0;
}

.RaLayout-content-4 > h4, .jss4 > h4 {
    width: 100%;
    max-width: 1200px;
    margin: 16px auto 0;
}

.list-page > .MuiToolbar-root, .list-page > .MuiToolbar-root {
    padding: 0 11px 0 64px !important;
}

.MuiDrawer-paper .MuiButton-label {
    justify-content: flex-start;
}

.MuiDrawer-paper .MuiButton-label .MuiButton-startIcon {
    margin: 0 32px 0 4px;
}

.menu-item-active {
    color: #702588 !important;
    background-color: #f5eff6 !important;
}

.menu-item-active .MuiSvgIcon-root, .jss450 .MuiSvgIcon-root {
 fill: #702588 !important;
}

.edit-page .MuiPaper-root {
    background: #FAFAFA !important;
    box-shadow: none;
}
.create-page .MuiPaper-root {
    background: #FAFAFA !important;
    box-shadow: none;
}

@media (max-width: 959.95px) {
    .list-page > .MuiToolbar-root, .list-page > .MuiToolbar-root {
        padding: 0 !important;
    }

    .MuiDrawer-paper {
        width: 96% !important;
    }

    .MuiDrawer-paper .RaMenuItemLink-active-566, .MuiDrawer-paper .jss450 {
        color: #702588 !important;
        background-color: #f5eff6 !important;
        border-radius: 4px;
    }
}

@media (min-width: 960px) {
    .MuiDrawer-paper .MuiMenuItem-root:first-child {
        align-self: flex-end;
        min-width: 24px;
        margin-top: 16px;
    }

    .sidebar-closed .MuiDrawer-paper .MuiMenuItem-root:first-child {
        align-self: center;
    }

    .MuiDrawer-paper .MuiMenuItem-root:first-child .MuiListItemIcon-root {
        min-width: 16px !important;
    }

    .MuiDrawer-paper .MuiMenuItem-root:first-child .MuiListItemIcon-root svg {
        width: 16px;
        height: 16px;
    }

    .MuiDrawer-paper {
        position: sticky !important;
        top: 0 !important;
        height: 100vh !important;
        overflow-y: auto !important;
    }
}

.MuiTableCell-sizeSmall:first-child {
    padding-left: 24px;
}

.MuiTableCell-sizeSmall:last-child {
    padding-right: 11px !important;
    text-align: right;
}

.MuiTableCell-sizeSmall:last-child .MuiSvgIcon-root {
    display: none;
}

.MuiTablePagination-caption {
    padding-right: 16px;
}

.edit-page .MuiToolbar-root {
    display: flex;
    justify-content: center;
}

.simple-form {
    max-width: 360px;
    padding: 20px 100px;
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.12);
    box-shadow: 0 2px 5px rgba(0,0,0,.15);
    margin: 0 auto 10px;
}

.simple-form .MuiFormControl-root {
    width: 100% !important;
}

.simple-form .MuiToolbar-root {
    background: transparent;
    padding: 0 16px;
}

.simple-form .MuiToolbar-root .MuiSvgIcon-root {
    display: none;
}

.list-page {
    background: white;
    box-shadow: 0 2px 5px rgba(0,0,0,.15);
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    margin-bottom: 120px;
}

.form-toolbar {
    justify-content: space-between !important;
    align-items: stretch !important;
    flex-wrap: wrap;
}

.form-toolbar > *:not(.delete-button) {
    flex-grow: 1;
    padding: 0 !important;
}

.delete-button {
    margin-bottom: 24px !important;
}

.delete-button:hover {
    background: transparent !important;
}

.delete-button .MuiButton-label {
    justify-content: flex-start;
}

.delete-button:not(.Mui-disabled) .MuiButton-label {
    color: rgba(0, 0, 0, .87);
}

.delete-button .MuiSvgIcon-root {
    display: block !important;
}

#other-buttons {
    margin-right: 8px;
}

#other-buttons a {
    width: 100%;
    height: 100%;
    color: rgba(0, 0, 0, .87);
    border-color: rgba(0, 0, 0, .12);
}

.form-toolbar {
    min-height: initial !important;
}

.MuiDialogActions-root .MuiSvgIcon-root {
    display: none;
}

@media (max-width: 600px) {
    .save-bottom-fixed {
        position: fixed !important;
        bottom: 0 !important;
        width: 92% !important;
        margin-bottom: 25px !important;
        left: 4% !important;
    }

    .save-bottom-fixed:after {
      content: '';
        position: fixed;
        bottom: 0;
        width: 96% !important;
        height: 25px !important;
        left: 2% !important;
        background-color: white;
    }
}

/*.Mui-selected:hover {*/
/*    background-color: white !important;*/
/*}*/
