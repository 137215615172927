#time-picker ul li span.TRYHO {
  display: none;
}

#time-picker ul li p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 60px;
  line-height: 71px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#time-picker ul::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE and Edge */
#time-picker ul {
  padding-left: 30px;
  -ms-overflow-style: none !important;
}